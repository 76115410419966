const client_ids = {
  base_url: process.env.REACT_APP_BASE_URL,
  zoom: process.env.REACT_APP_ZOOM_ID,
  dropbox: "5f3dr0uvbyshvbo",
  vimeo: "b852409b7dae4102aeb96cb128b211c9aa1beb95",
  onedrive: "be952263-231b-48c6-9089-e071fbcbe42d",
  youtube: "112091216604-9g21dla4cvc4j8nj4s08e64938l0rh35.apps.googleusercontent.com",
  drive: "112091216604-s3mblj87ommipm0k46ddh69njk8se2q7.apps.googleusercontent.com"
};

const oauth = {
  zoom: `https://zoom.us/oauth/authorize?response_type=code&client_id=${client_ids.zoom}&redirect_uri=${client_ids.base_url}/auth/zoom`,
  dropbox: `https://www.dropbox.com/oauth2/authorize?client_id=${client_ids.dropbox}&token_access_type=offline&response_type=code&redirect_uri=${client_ids.base_url}/admin/settings/dropbox`,
  vimeo: `https://api.vimeo.com/oauth/authorize?response_type=code&client_id=${client_ids.vimeo}&redirect_uri=${client_ids.base_url}/admin/settings/vimeo&state=vimeo4425&scope=private%20upload`,
  onedrive: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_ids.onedrive}&scope=files.readwrite.appfolder%20offline_access%20openid&&response_type=code&redirect_uri=${client_ids.base_url}/admin/settings/onedrive`,
  youtube: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${client_ids.youtube}&redirect_uri=${client_ids.base_url}/admin/settings/youtube&response_type=code&access_type=offline&promp=select_account&scope=https://www.googleapis.com/auth/youtube.upload`,
  drive: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${client_ids.drive}&redirect_uri=${client_ids.base_url}/admin/settings/drive&response_type=code&access_type=offline&promp=select_account&scope=https://www.googleapis.com/auth/drive.file%20openid`,
};

export default oauth;
